<template>
  <div>
   
   <PrivacyHeader>   
      <img 
        slot="logo"
        src="../assets/images/logo/final_logo.png"        
      />
   </PrivacyHeader> 
   
   
   <!-- Start Breadcrump Area  -->
    <!--<div
      class="breadcrumb-area rn-bg-color bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="logo text-left">
              <img
        slot="logo"
        src="../assets/images/logo/final_logo.png"
        class="logo-light"
      />
            </div>    
          </v-col> 
        </v-row>
      </v-container>
    </div>-->
    <!-- End Breadcrump Area  -->

 <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="inner">
                <h2>Privacy Policy</h2>
                <p>
                  BY INSTALLING THE GAMES ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTING TO, ACCESSING, AND/OR USING THE GAMES, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS, AND/OR USE THE GAMES AND YOU ARE REQUESTED TO PROMPTLY ERASE THEM, AND ANY PART THEREOF, FROM YOUR MOBILE DEVICE.
                </p>
                <p class="subtitle">
                    What features we use
                </p>
                <p>
                 Our Games features provided by mobile device, and don't collect any information from user.
                </p>
                <p class="subtitle">
                    Links to third-party websites and services
                </p>
                <p>
                 The Games may contain links to other websites and online services, including third-party advertisements. If you choose to click through to one of these other websites or online services, please note that any information you may provide will be subject to the privacy policy and other terms and conditions of that websites or service, and not to this Privacy Policy. We do not share your data with third-party websites or services, and the fact that a link to such website or service does not appear in the Games. You agree that our application will have no liability for any matters relating to a third-party website or service that you provide information to, including their collection and handling of that information.
                </p>
                <p class="subtitle">
                    Compliance with laws and law enforcement
                </p>
                <p>
                 ("We") reserves the right to share your information with third parties if we believe such action is necessary in order to  
                <ul>
                    <li>
                    Conform to the requirements of the law or to comply with legal process served upon us </li>
                <li>	Protect or defend our legal rights or property.</li>
                <li>	Investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential
                threats to the physical safety of any person or violations of the terms and conditions of using our Games and Games and Services </li>
                </ul>
                </p>
                <p class="subtitle">
                    Allow for play Games Permissions
                </p>
                <p>
                 <ul>
                    <li>	Camera: Take photos and videos
                    <li>  Storage modify or delete the contents of your USB storage  </li>
                    <li>	read the contents of your USB storage  </li>
                    <li>	Photo media files  </li>
                    <li>	Read phone state </li>
                    <li>	Internet  </li>
                    <li>	Billing permission</li>
                </ul>
                </p>
                <p class="subtitle">
                    Security
                </p>
                <p>
                    Cyber Tarzan has implemented technology and policies to safeguard your privacy from unauthorized
                    access and improper use. Cyber Tarzan has implemented technology and policies to safeguard your privacy from
                    unauthorized access and improper use 
                </p>
                <p class="subtitle">
                  How we Handle your Location Data and Personal Information
                </p>
                <p>
                    This application will ask for GPS location permission for the functionality to work properly. This application will not share your GPS location with any third-party entity. We are storing GPS location on secure servers with encryption.
                    This application will ask for Email address or Cell Phone Number for login to work properly. This application will not share these credentials with any third-party entity. We are storing these credentials on secure servers with encryption.
                </p>
                <p class="subtitle">
                    Changes to our Privacy Policy
                </p>
                <p>
                 This Privacy Policy may be updated for any reason.
                </p>
                <p class="subtitle">
                Contact us
                </p>
                <p>
                    If you have any questions regarding privacy while using the Games, or have questions
                    about our practices, please contact us via email.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->  
    <Footer />
  </div>
</template>

<script>
  import Footer from "../components/footer/FooterTwo";
  import PrivacyHeader from "../components/header/PrivacyHeaderCyberTarzan.vue";
  export default {
    components: {
      Footer,
      PrivacyHeader,
    },
    data() {
      return {
      };
    },
  };
</script>
